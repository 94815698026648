import { signatures } from "../constants"

export const base64ToFile = (base64: string, fileName: string) => {
    const [metadata, data] = base64.split(',')
    const match = metadata.match(/:(.*?);/)
    const mimeType = match ? match[1] : ''
    try {
      const binaryString = atob(data)
      const len = binaryString.length
      const arrayBuffer = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        arrayBuffer[i] = binaryString.charCodeAt(i)
      }
      const file = new File([arrayBuffer], fileName, { type: mimeType })
      return file
    } catch (error) {
      throw error
    }
  }

  const checkIsXlsx = (view:Uint8Array<ArrayBuffer>) => {
    return view.some((byte, idx) => {
      return (
        idx + 10 < view.length &&
        view.slice(idx, idx + 12).every((value, i) => {
          const xlsxSignature = [
            0x78, 0x6C, 0x2F, 0x77, 0x6F, 0x72, 0x6B, 0x62, 0x6F, 0x6F, 0x6B, 0x2E
          ]; 
          return value === xlsxSignature[i];
        })
      );
    });
  }

  const checkZipFile = (base64Data:string) => {
    const binaryString = atob(base64Data);
    const buffer = new ArrayBuffer(binaryString.length);
    const view = new Uint8Array(buffer);
    
    for (let i = 0; i < binaryString.length; i++) {
      view[i] = binaryString.charCodeAt(i);
    }  
    const isXlsx = checkIsXlsx(view)
    
    if (isXlsx) {
      
      return signatures.find((sig) => sig.signature === "504B0304" && sig.ext === ".xlsx");
    } else {
      
      return signatures.find((sig) => sig.signature === "504B0304" && sig.ext === ".docx");
    } 
  }

  export const getFileInfoFromBase64 = (base64Data: string) => {      
    const raw = atob(base64Data.substring(0, 16)); 
    const hexSignature = Array.from(raw)
      .slice(0, 4) 
      .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
      .join("")
      .toUpperCase();
    let fileInfo; 
    if (hexSignature === "504B0304") { 
      fileInfo = checkZipFile(base64Data)
    } else {
      fileInfo = signatures.find((sig) => sig.signature === hexSignature);
    }  
    return fileInfo || { mime: "application/octet-stream", ext: ".bin" };
  };


 export const parseDocForAPI = (selectedFile: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!selectedFile) {
        reject('No file selected')
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        try {
          let base64String = (reader.result as string)
            ?.replace('data:', '')
            ?.replace(/^.+,/, '')

          console.log('Reader string', String(reader.result).substring(0, 50))
          let ext = selectedFile.name.split('.').pop()
          base64String = ext + ',' + base64String
          resolve(base64String)
        } catch (error) {
          reject('Error processing file')
        }
      }

      reader.onerror = () => {
        reject('Error reading file')
      }

      reader.readAsDataURL(selectedFile)
    })
  }

  const getFormattedTimestamp = () => {
    const now = new Date(Date.now());
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

export const generateFileName = (fullName: string) => {
  return `${fullName}-${getFormattedTimestamp()}`
}