import '../NewSchedule.css';

import { Grid, TextField, debounce } from '@material-ui/core'
import React, { Component } from 'react';

import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import HotelIcon from '@material-ui/icons/Hotel';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import SelectInput from '../../SelectInput';
import { CustomLoader } from '../../CustomLoader';
import { withTranslation } from 'react-i18next';
import { CustomNotificationBadge } from '../../CustomNotificationBadge';

class CustomerForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customers: [],
            customer: '',
            customerId: '',
            addresses: [],
            address: '',
            zone: '',
            location: '',
            province: '',
            isLoading: false,
            hasDocumentation: false,
        }
        this.myRefCustomerId = React.createRef();
    }

    componentDidMount() {
        const { value } = this.props;
        if (value) {
            this.getEditCustomerAPI(value.customer);
            this.handleValue(value.customer, 'customer');
            this.handleValue(value.address, 'address');
        }
    }

    async componentWillReceiveProps(next) {        
        const { value } = this.props;
        const { customers } = this.state;        
        if(next?.value && next.value?.loadAffiliateData){
            const {customer} = next.value            
            this.setState((prevState) => ({...prevState, isLoading: true}))
            const newCustomers = await this.handleSingleCustomer(customer) 
            const {customerId, hasDocumentation} = newCustomers[0] 
            this.getAddresses({personId: customer, customerId: customerId, hasDocumentation: hasDocumentation})
            this.setState((prevState) => ({...prevState, customers: newCustomers}))
        }
        else if (value?.customer && customers?.length > 0) {
            const customerIndex = customers.findIndex(item => item.id === value.customer);
            if (customerIndex !== -1) {                
                const customerId = customers[customerIndex].customerId;
                this.setState((prevState) => {                    
                    return{...prevState, customerId, hasDocumentation: customers[customerIndex].hasDocumentation} });
            }
                
        } 
    }


    formatAddresses = (addresses) => addresses.map(a => ({
        addressName: addresses,
        id: a.addressId,
        value: `${a.street} ${a.number}`,
        zone: a.geographicZone ? a.geographicZone.detail : '',
        geographicZoneId: a.geographicZone  ? a.geographicZone.geographicZoneId : 0,
        location: a.location.name,
        province: a.provinceName,
    }));

    async handleSingleCustomer(customer){
        return await new Promise((resolve,reject) => PersonApiInvoker.getCustomer(customer,
            (data) => {          
              const hasDocumentation = data?.resourceList?.length > 0 || data.hasDocumentation;
                const builtCustomer = this.buildCustomer({...data.customer, hasDocumentation})                
               resolve([{...builtCustomer}])
            },
            (error) => {reject(error)}))
    }

    propsOnFilledCustomer(newState) {
        this.props.onFilledCustomer(newState);
    }

    setAddress(addresses, value) {
        const {personId, customerId, hasDocumentation} = value
        let address = {
            address: '',
            zone: '',
            location: '',
            province: '',
            geographicZoneId: '',
        };
        if (addresses.length === 1) {
     
            const selectedAddress = addresses[0];
            address = {
                address: selectedAddress.id,
                zone: selectedAddress.zone,
                location: selectedAddress.location,
                province: selectedAddress.province,
                geographicZoneId: selectedAddress.geographicZoneId,
            };
        } else if  (addresses.length > 1) {
            const defaultAddressContainer = addresses.find(item =>
              item.addressName.some(addr => addr.addressDefault) 
            );
          
            if (defaultAddressContainer) {
              const addressDetails = defaultAddressContainer.addressName.find(addr => addr.addressDefault);
              
              if (addressDetails) {
                address = {
                  address: addressDetails.addressId,
                  zone: addressDetails.geographicZone.detail,
                  location: addressDetails.location.name,
                  province: addressDetails.provinceName,
                  geographicZoneId: addressDetails.geographicZone.geographicZoneId,
                };
              }
            }
          }
          const newState ={            
            customer: personId,
            customerId: customerId,
            addresses: addresses,
            address: address.address,
            zone: address.zone,
            location: address.location,
            province: address.province,
            geographicZoneId: address.geographicZoneId,
            isLoading: false,
            hasDocumentation: hasDocumentation
        }        
        this.setState((prevState) =>({
            ...prevState,
            ...newState,
        }), () => { this.propsOnFilledCustomer(newState) });
    }

    getAddresses(value) {      
        const {personId} = value
        PersonApiInvoker.getPersonsAddress(personId,false, data => {
            if (data?.length) {
                const formattedAddresses = this.formatAddresses(data)
                this.setAddress(formattedAddresses, value);
            } else {
                const newState = {
                    
                    addresses: [],
                    address: '',
                    zone: '',
                    location: '',
                    province: '',
                    geographicZoneId: '',
                    isLoading: false,
                }
                this.setState((prevState) => ({
                    ...prevState,
                    ...newState
                }), () => {
                    this.propsOnFilledCustomer(newState);
                });
            }
        }, null);
    }

    async handleValue(value, state) {
        this.setState((prevState) => ({...prevState, isLoading: true }));
        const { addresses, customers } = this.state;
        if (state === 'customer') {      
            if(!value){
                this.setState(prevState => ({...prevState, customers: [], isLoading: false }));
                return
            }      
            let customersData = customers            
            if(customers?.length < 1){                
                customersData = await this.handleSingleCustomer(value)
            }       
            const customerIndex = customersData?.findIndex(item => item.id === value);
            const {hasDocumentation, customerId} = customersData[customerIndex]
            if (customerIndex !== -1) {                
                this.getAddresses({personId: value, customerId: customerId, hasDocumentation: hasDocumentation});
                if(customersData !== customers){
                    this.setState((prevState) => ({...prevState, customers: customersData}))
                }
            }
        }
        if (state === 'address') {
            const addressIndex = addresses.findIndex(item => item.id === value)
            if (addressIndex !== -1) {
                const newState = {
                    address: addresses[addressIndex].id,
                    geographicZoneId: addresses[addressIndex].geographicZoneId,
                    zone: addresses[addressIndex].zone || '',
                    location: addresses[addressIndex].location || '',
                    province: addresses[addressIndex].province || '',
                    isLoading: false,
                }
                this.setState((prevState) => ({
                    ...prevState,
                  ...newState
                }), () => {
                    this.propsOnFilledCustomer(newState);
                });
            }
        }
    }

    buildCustomer = (customer) => {
        if (!customer) {
            return {};
        }
        return {
            customerId: customer.id,
            id: customer.personId,
            value: `${customer.lastName} ${customer.firstName}`,
            hasDocumentation: customer.hasDocumentation
        }
    }

    formatCustomers = (customers) => {
        if (!customers.length) {
            return [];
        }
        return customers.map(e => this.buildCustomer(e)).sort((a, b) => a.value.localeCompare(b.value));
    }

    getCustomersAPI = (text) => {
        PersonApiInvoker.getCustomersOptionsActive(text, data => {
            const customers = this.formatCustomers(data);
            this.setState(prevState => ({...prevState, customers }));
            this.props.onGetCustomers(data);
        }, (error) => console.error('** error getCustomersOptions', error));
    }

    getEditCustomerAPI = (personId) => {
        PersonApiInvoker.getCustomer(personId, data => {           
            const customers = this.buildCustomer({...data.customer, hasDocumentation: data?.resourceList.length > 0 ? true : false});            
            this.setState((prevState) => ({...prevState, customers: [customers] }));
            this.props.onGetCustomers([data.customer]);
        }, _ => {
            this.setState((prevState) => ({...prevState, loading: false }))
        });
    }

    getCustomersAPIDebounced = (value) => debounce(this.getCustomersAPI(value), 500);

    clearCustomerFieldSearch() {
        this.setState((prevState) => (
          {
              ...prevState,
              filter: {
                  ...prevState.filter,
                  customerId: '',
              },
              filterShow: {
                  ...prevState.filterShow,
                  customerId: '',
              },
              customers: [],
          }
        ));
    }

    handleCustomerOptions(event) {
        if (event?.target?.value === undefined) {
            return;
        }
        const value = (event?.target?.value);
        const isValue = (event?.target) && (value && value.length >= 3);
        if (!event) {
            this.clearCustomerFieldSearch();
        }
        if (isValue) {
            this.setState((prevState) => ({...prevState, customers: [] }));
            this.getCustomersAPIDebounced(value);
        }
        const isInvalidValueAndCustomers = event?.target?.value?.length < 3 && this.state.customers.length > 0;
        if (isInvalidValueAndCustomers) {
            this.clearCustomerFieldSearch();
        }
    }

    onFocusCustomerFilter(event) {
        event.persist();
        if (!(this.state?.customerId)) {
            this.clearCustomerFieldSearch();
        }
    }

    render() {
        const { customers, customer, customerId, addresses, address, zone, location, province, isLoading, hasDocumentation } = this.state;
        const { disabled, checkInvalid, t } = this.props;
        return (
          <Card id="card-customer" className="card-customer-form">
            <CardHeader icon>
              <CardIcon color="primary">
                <HotelIcon />
              </CardIcon>
              <h3 className="card-icon-title">{t('appointment.customer')}</h3>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <CustomLoader />
              ) : (
                <GridContainer className="card-customer-container">
                  <GridItem xs={12} sm={12}>
                    {hasDocumentation && (
                      <CustomNotificationBadge
                        notification={
                          t('customers.badge.resources.notification')
                        }
                        type="info"
                      />
                    )}
                  </GridItem>
                  <Grid item xs={12} sm={6}>
                    <SelectInput
                      ref={this.myRefCustomerId}
                      className="no-padding"
                      link={this.props.linkAccept}
                      label={t('appointment.new.customer.customer')}
                      elements={customers}
                      value={customer}
                      onSelectedValue={(value) =>
                        this.handleValue(value, 'customer')
                      }
                      onInputChange={(ev) => this.handleCustomerOptions(ev)}
                      freeSolo={true}
                      isWaitLetters={true}
                      getOptionSelected={(option) => option?.id || null}
                      onFocus={(ev) => this.onFocusCustomerFilter(ev)}
                      id="select-customer"
                      disabled={disabled}
                    />
                  </Grid>

                  <GridItem className="fullWidth-input" xs={12} sm={6}>
                    <TextField
                      id="input-id"
                      disabled
                      fullWidth
                      label={t('appointment.new.customer.id')}
                      value={(customer && customerId) || ''}
                    />
                  </GridItem>
                  <GridItem className="fullWidth-input" xs={12} sm={6}>
                    <SelectInput
                      id="select-address"
                      fullWidth
                      disabled={disabled}
                      label={t('appointment.new.customer.address')}
                      elements={addresses}
                      onSelectedValue={(value) =>
                        this.handleValue(value, 'address')
                      }
                      value={customer && address}
                      invalid={!address && checkInvalid}
                      errorText={
                        !address && checkInvalid ? t('error.required') : ''
                      }
                    />
                  </GridItem>
                  <GridItem className="fullWidth-input" xs={12} sm={6}>
                    <TextField
                      id="input-zone"
                      disabled
                      fullWidth
                      label={t('appointment.new.customer.zone')}
                      value={(customer && address && zone) || ''}
                    />
                  </GridItem>
                  <GridItem className="fullWidth-input" xs={12} sm={6}>
                    <TextField
                      id="input-location"
                      disabled
                      fullWidth
                      label={t('appointment.new.customer.location')}
                      value={(customer && address && location) || ''}
                    />
                  </GridItem>
                  <GridItem className="fullWidth-input" xs={12} sm={6}>
                    <TextField
                      id="input-province"
                      disabled
                      fullWidth
                      label={t('appointment.new.customer.province')}
                      value={(customer && address && province) || ''}
                    />
                  </GridItem>
                </GridContainer>
              )}
            </CardBody>
          </Card>
        )
    }
}

CustomerForm.propTypes = {
    t: PropTypes.func,
    onGetCustomers: PropTypes.func,
    onFilledCustomer: PropTypes.func,
    value: PropTypes.object,
    disabled: PropTypes.bool,
    checkInvalid: PropTypes.bool,
    linkAccept:  PropTypes.bool,
}

export default withTranslation()(CustomerForm);
