import Tooltip from '@material-ui/core/Tooltip'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../CustomButtons/Button'
import {
  BodyResource,
  CustomerResourceFormType,
  errorResponseType,
  getCustResourcesResType,
  postCustResourcesResType,
  RowData,
  useCustomerResourcesProps,
} from '../types'
import { maxFileSize, validTypes } from '../constants'
import {
  deleteCustomerResources,
  getCustomerResources,
  postCustomerResources,
  putCustomerResources,
} from '../../../api/CustomerResourcesInvoker/CustomerResourcesInvoker'
import { Assignment, Delete, Edit } from '@material-ui/icons'
import { base64ToFile, generateFileName, getFileInfoFromBase64 } from '../utils'

export const useCustomerResources = ({
  readOnlyMode,
  personId,
  fullName,
}: useCustomerResourcesProps) => {
  const { t } = useTranslation()

  const [docModalOpen, setDocModalOpen] = useState<boolean>(false)

  const [resources, setResources] = useState<CustomerResourceFormType[]>([])

  const [form, setForm] = useState<CustomerResourceFormType>({
    detail: null,
    file: null,
  })

  const [error, setError] = useState<string | null>(null)

  const [dragOver, setDragOver] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [isFetching, setIsFetching] = useState<boolean>(false)

  const [selectedResource, setSelectedResource] = useState<number | null>(null)

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false)

  const [success, setSuccess] = useState<boolean>(false)

  const [alertMessage, setAlertMessage] = useState('')

  const parseDocToFile = (base64Data: string) => {
    const base64WithPrefix = getFileInfoFromBase64(base64Data)
    return base64ToFile(
      `${base64WithPrefix?.mime},${base64Data}`,
      `${generateFileName(fullName)}${base64WithPrefix?.ext}`
    )
  }

  const fetchCustomerResources = async () => {
    try {
      const res: getCustResourcesResType[] = await new Promise(
        (resolve, reject) =>
          getCustomerResources({
            customerId: String(personId),
            callback: (data: getCustResourcesResType[]) => {
              resolve(data)
            },
            callerror: (error: errorResponseType) => {
              console.error(error)
              reject()
            },
          })
      )
      let docs: CustomerResourceFormType[] = []
      if (res.length > 0) {
        docs = res.map((resource: getCustResourcesResType) => {
          const file = parseDocToFile(resource.file64Value)
          return {
            detail: resource.detail,
            file: file,
            id: resource.customerResourcesId,
            fileUrl: resource.value,
          }
        })
      }
      setResources(docs)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
    setDragOver(true)
  }

  const handleDragLeave = () => {
    setDragOver(false)
  }

  const canAddResource = useMemo(
    () => (resources?.length < 3 && !isLoading ? true : false),
    [resources, isLoading]
  )

  const canUploadResource = useMemo(() => {
    return form.file && form.detail ? true : false
  }, [form.file, form.detail])

  const parseDocForAPI = (selectedFile: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!selectedFile) {
        reject(t('customers.new.resources.noFileSelected.error') as string)
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        try {
          let base64String = (reader.result as string)?.replace(
            /^data:[a-zA-Z0-9-+&%#/=]+;base64,/,
            ''
          )

          resolve(base64String)
        } catch (error) {
          reject(error)
        }
      }

      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsDataURL(selectedFile)
    })
  }

  const handlePUTResource = async (body: BodyResource) => {
    try {
      const res: postCustResourcesResType = await new Promise(
        (resolve, reject) =>
          putCustomerResources({
            body: body,
            resourceId: String(selectedResource),
            callback: (data: postCustResourcesResType) => {
              resolve(data)
            },
            callerror: (error: errorResponseType) => {
              console.error(error)
              reject()
            },
          })
      )
      if (res.customerResourcesId > 0) {
        setDocModalOpen(false)
        setForm({ detail: null, file: null })
        setIsLoading(true)
        setSelectedResource(null)
        fetchCustomerResources()
      }
    } catch (error) {
      alert(error)
    }
  }

  const handlePOSTResource = async (body: BodyResource) => {
    try {
      const res: postCustResourcesResType[] = await new Promise(
        (resolve, reject) =>
          postCustomerResources({
            body: body,
            customerId: String(personId),
            callback: (data: postCustResourcesResType[]) => {
              resolve(data)
            },
            callerror: (error: errorResponseType) => {
              console.error(error)
              reject()
            },
          })
      )
      if (res.length > 0) {
        if (res[0].customerResourcesId) {
          setDocModalOpen(false)
          setForm({ detail: null, file: null })
          setIsLoading(true)
          fetchCustomerResources()
        }
      }
    } catch (error) {
      alert(error)
    }
  }

  const handleDeleteResource = async () => {
    try {
      await new Promise((resolve, reject) => {
        try {
          deleteCustomerResources({
            resourceId: String(selectedResource),
            callback: (data: {}) => {
              resolve(data)
            },
            callerror: (error: errorResponseType) => {
              console.error(error)
              reject()
            },
          })
        } catch (e) {
          console.error(e)
        }
      })
      setSuccess(true)
      setIsLoading(true)
      setForm({ detail: null, file: null })
      setSelectedResource(null)

      fetchCustomerResources()
    } catch (error) {
      alert(error)
    }
  }

  const handleAddDocument = async () => {
    setIsFetching(true)
    const parsedDoc = form?.file && (await parseDocForAPI(form.file))
    if (!parsedDoc || parsedDoc === null) {
      setIsFetching(false)
      alert(t('customers.new.resources.noFileSelected.error'))
      return
    }
    const body = {
      fileDetails: form?.detail,
      file64: parsedDoc,
    }

    try {
      if (selectedResource && selectedResource >= 0) {
        await handlePUTResource(body)
      } else {
        await handlePOSTResource(body)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleModalActions = (type: 'add' | 'close') => {
    const btnsFn: { add: Function; close: Function } = {
      add: handleAddDocument,
      close: () => {
        setForm({ file: null, detail: null })
        setDocModalOpen(false)
      },
    }

    return btnsFn[type]()
  }

  const handleOpenModal = (id?: number) => {
    if (id && id >= 0) {
      setSelectedResource(id)
    } else {
      setSelectedResource(null)
    }
    setDocModalOpen(true)
  }

  const handleOpenDeleteModal = (id: number) => {
    setSelectedResource(id)

    setDeleteAlert(true)
  }

  const closeAlert = () => {
    setAlertMessage('')
    setSuccess(false)
    setDeleteAlert(false)
  }

  const validateFile = (selectedFile: File | null) => {
    if (!selectedFile) return false

    if (!validTypes.includes(selectedFile.type)) {
      setError(t('customers.new.resources.fileType.error') as string)
      return false
    }

    if (selectedFile.size > maxFileSize) {
      setError(t('customers.new.resources.maxSize.error') as string)
      return false
    }

    setError(null)
    return true
  }

  const handleFileChange = (selectedFile: File | null) => {
    if (validateFile(selectedFile)) {
      setForm((prevState: CustomerResourceFormType) => ({
        ...prevState,
        file: selectedFile,
      }))
    } else {
      setForm((prevState: CustomerResourceFormType) => ({
        ...prevState,
        file: null,
      }))
    }
  }

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleFileChange(event.target.files?.[0] || null)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target

    setForm((prevState: CustomerResourceFormType) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleFileChange(event.dataTransfer.files[0])
    }
  }

  const tableData = useMemo(
    () => ({
      defaultPageSize: 3,
      colorsColls: ['primary'],
      sortable: true,
      tableHeaderColor: 'primary',
      showPaginationTop: false,
      showPaginationBottom: false,
      tableHead: [
        {
          Header: t('customers.new.resources.detail.text'),
          accessor: 'detail',
        },
        {
          Header: !readOnlyMode ? t('actions') : '',
          accessor: 'actions',
          sortable: false,
          Cell: (row: RowData) => {
            const url = URL.createObjectURL(row.original.file)
            return (
              <div className="appointments-actions">
                <Tooltip title={t('common.download') as string}>
                  <span>
                    <a
                      href={url}
                      download={row.original.file.name}
                      style={{ textDecoration: 'none' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button simple justIcon color="primary" id="Descargar">
                        <Assignment />
                      </Button>
                    </a>
                  </span>
                </Tooltip>

                {!readOnlyMode && (
                  <>
                    <Tooltip title={t('common.edit') as string}>
                      <span>
                        <Button
                          simple
                          justIcon
                          color="success"
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault()
                            handleOpenModal(row.original.id)
                          }}
                          id="editar"
                        >
                          <Edit />
                        </Button>
                      </span>
                    </Tooltip>
                    <Tooltip title={t('common.remove') as string}>
                      <span>
                        <Button
                          simple
                          justIcon
                          color="danger"
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault()
                            handleOpenDeleteModal(row.original.id)
                          }}
                          id="delete"
                        >
                          <Delete />
                        </Button>
                      </span>
                    </Tooltip>
                  </>
                )}
              </div>
            )
          },
        },
      ],
      tableData: resources,
      loading: isLoading,
    }),
    [isLoading, resources, readOnlyMode]
  )

  useEffect(() => {
    if (!isLoading) setIsFetching(false)
  }, [isLoading])

  useEffect(() => {
    if (personId) {
      fetchCustomerResources()
    }
  }, [personId])

  useEffect(() => {
    if (selectedResource) {
      const resourceIndex = resources.findIndex(
        (resource) => selectedResource === resource.id
      )
      if (resourceIndex >= 0) {
        const { detail, file } = resources[resourceIndex]
        setForm({ detail: detail, file: file })
      }
    }
  }, [selectedResource])

  return {
    tableData,
    docModalOpen,
    handleModalActions,
    handleOpenModal,
    canAddResource,
    form,
    error,
    handleDragOver,
    handleDragLeave,
    dragOver,
    handleFileInputChange,
    handleDrop,
    handleChange,
    isLoading,
    isFetching,
    canUploadResource,
    deleteAlert,
    closeAlert,
    success,
    alertMessage,
    handleDeleteResource,
  }
}
