import '../NewSchedule.css';

import React, { Component } from 'react';

import BusinessApiInvoker from '../../../api/BusinessApiInvoker';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import { HomeRepairService } from '../../../icons';
import PropTypes from 'prop-types';
import SelectInput from '../../SelectInput';
import { debounce, Typography } from '@material-ui/core';
import lodash from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getAffiliateById } from '../../../api/AffiliateApiInvoker'
import PersonApiInvoker from '../../../api/PersonApiInvoker'
import { CustomLoader } from '../../CustomLoader';

class ProviderForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      financiers: [],
      financier: '',
      affiliateId: '',
      affiliates: [],
      providers: [],
      provider: '',
      dateStart: '',
      dateEnd: '',
      isDateRangeValid: true,
      dateRangeInvalidText: '',
      isLoading: false,
    }
    this.myRefAffiliateId = React.createRef()
  }

  componentDidMount() {
    const { value } = this.props;
    this.getProviders();
    this.getFinanciers();
    if (value) {
      this.handleValue(value.financier, 'financier');
      this.handleValue(value.provider, 'provider');
      this.handleValue(value.dateStart, 'dateStart');
      this.handleValue(value.dateEnd, 'dateEnd');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.customerId && this.props.customerId.customer !== nextProps.customerId.customer) {
      this.setState((prevState) => ({...prevState,isLoading: true}))
      this.getFinanciers({ customerId: nextProps.customerId});      
    }
  }

  async getSingleAffiliate(affiliateId){
    return await new Promise((resolve) => getAffiliateById({
      value: affiliateId,
      callback: (affiliateData) => {
        if (affiliateData?.length > 0) {
          const affiliateIndex = affiliateData.findIndex(
            (obj) => obj.afiliatedId === affiliateId
          )
          const formattedAffiliates = this.formatAffiliates([
            affiliateData[affiliateIndex],
          ])
          resolve(formattedAffiliates)
        }
      },
      callerror: (error) => console.error(error),
    }))
  }

  async getDataFinanciers(value, customer) {
    const stopLoad = !value || value?.customerId?.address;
    BusinessApiInvoker.getCustomersFinanciers(
      customer,
      async (data) => {
        if (!data.length) {
          this.setState(
            (prevState) => ({
              ...prevState,
              financiers: [],
              financier: '',
              affiliateId: '',
              isLoading: false,
            }),
            () => {
              this.props.onFilledFinancier({ financierId: '', affiliateId: '' })
            }
          )
          return
        }
        const { affiliateId } = this.state
        let financierIndex = -1
        if (affiliateId) {
          financierIndex = data.findIndex(
            (obj) => obj.affiliateId === affiliateId
          )
        }
        if (financierIndex >= 0) {
          const { financierId, affiliateId } = data[financierIndex]
          this.setState((prevState) => ({
            ...prevState,
            financiers: data,
            financier: financierId,
            isLoading: !stopLoad
          }))
          if (this.props.onFilledFinancier) {
            this.props.onFilledFinancier({
              financierId: financierId,
              affiliateId: affiliateId,
            })
          }
        } else {
          const { affiliateId, financierId } = data[0]
          const affiliates = await new Promise((resolve) => {
            resolve(this.getSingleAffiliate(affiliateId))          
          })
          if (affiliates) {
            this.setState((prevState) => ({
              ...prevState,
              affiliates: affiliates,
              affiliateId: affiliateId,
              financiers: data,
              financier: financierId,
              isLoading: !stopLoad
            }))
            this.props.onFilledFinancier({
              financierId: financierId,
              affiliateId: affiliateId,
            })
          }
        }
      },
      null
    )
  }

  getFinanciers = (value) => {
    let customerId;
    if (lodash.get(value, 'customerId.customer', null)) {
      customerId = value.customerId;
    } else if (lodash.get(this, 'props.customerId.customer', null)) {
      customerId = this.props.customerId;
    } else if (lodash.get(this, 'props.value.customerId.customer', null)) {
      customerId = this.props.value.customerId;
    }
    if (customerId?.customer) {
      this.getDataFinanciers(value, customerId.customer);  
    }

  }

  getProviders(data) {
    const { value, disabled } = this.props
    BusinessApiInvoker.getServiceProviders((providers) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          providers: this.formatProviders(providers),
          provider:
            data ||
            (disabled && value.provider && value.provider.serviceProviderId),
        }),
        () => {
          if (this.state.providers.length === 1) {
            this.setState((prevState) => ({
              ...prevState,
              provider: prevState.providers[0].id,
            }))
          }
        }
      )
    }, null);
  }

  formatProviders(providers) {
    const result = providers.map(p => ({
      id: p.serviceProviderId,
      value: p.fantasyName,
    }));
    return result;
  }

  formatFinanciers(financiers) {
    const result = financiers.map(f => ({
      id: f.financierId,
      value: f.financier.fantasyName,
      affiliateId: f.affiliateId,
    }));
    return result;
  }

  onDatesValue(value, state) {
    if (state === 'dateStart' && this.state.dateEnd && this.state.dateEnd < value) {
      this.setState((prevState) => ({ ...prevState, dateEnd: '' }))
    }
    if ((state === 'dateStart' || state === 'dateEnd') && this.state.dateStart && this.state.dateEnd) {
      this.onDateRange();
    }
  }

  onFinancerValue(value) {
    if (!this.state.financiers) {
      this.getFinanciers(value);
      return;
    }
    const financierIndex = this.state.financiers.findIndex(item => item.financierId === value);
    if (financierIndex !== -1) {
      this.setState(
        (prevState) => ({
          ...prevState,
          financier: value,
          affiliateId: prevState.financiers[financierIndex].affiliateId,
        }),
        () => {
          this.props.onFilledFinancier({
            financierId: this.state.financier,
            affiliateId: this.state.affiliateId,
          })
        }
      )
    }
  }

  async getCustomerData(personId) {
    return new Promise((resolve, reject) => {
      PersonApiInvoker.getCustomer(
        personId,
        (data) => {
          const customer = {
            customerId: data.customer.id,
            id: data.customer.personId,
            value: `${data.customer.lastName} ${data.customer.firstName}`,
          }
          resolve(customer)
        },
        (error) => {
          console.error('Error al obtener el cliente:', error)
          reject(error)
        }
      )
    })
  }

  async handleAffiliateIdFound(affiliateData) {
    const { id, personId } = affiliateData
    let data = {}

    try {
      this.setState((prevState) =>({
        ...prevState,
        affiliateId: id,
        isLoading: true,
      }))
      const customer = await this.getCustomerData(personId)
      if (customer) {
        data = {
          affiliateId: id,
          customer: personId,
          customerId: customer.customerId,
        }
      }
      this.props.onFilledAffiliate(data)
    } catch (error) {
      console.error(error)
      return
    }
  }

  handleValue(value, state) {
    this.setState((prevState) => ({ ...prevState, isLoading: true }))
    const { affiliates } = this.state
    if (state === 'affiliateId') {
      if(!value){
        this.setState((prevState) =>({...prevState, affiliates: [], isLoading: false}))
        return
      }
      const affiliateIndex = affiliates.findIndex((item) => item.id === value)
      if (affiliateIndex !== -1) {
        this.handleAffiliateIdFound(affiliates[affiliateIndex])
      }
    }else{
      this.setState((prevState) =>({...prevState, [state]: value }), () => {
          this.onDatesValue(value, state);

          this.props.onFilledProvider({
            provider: this.state.provider,
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
          });

          if (state === 'financier') {
            this.onFinancerValue(value)
          }
        }
      )
    }
  }

  clearAffiliateFieldSearch() {
    this.setState((prevState) => ({
      ...prevState,
      affiliates: [],
    }))
  }

  buildAffiliate(affiliate) {
    if (!affiliate) {
      return
    }
    return {
      personId: affiliate.personId,
      id: affiliate.afiliatedId,
      value: affiliate.afiliatedId,
    }
  }

  formatAffiliates(affiliates) {
    if (!affiliates.length) {
      return []
    }
    return affiliates
      .map((obj) => this.buildAffiliate(obj))
      .sort((a, b) => a.value.localeCompare(b.value))
  }

  getAffiliatesAPI(value) {
    getAffiliateById({
      value: value,
      callback: (data) => {
        const affiliates = this.formatAffiliates(data)
        this.setState((prevState) =>({...prevState, affiliates }))
      },
      callerror: (error) =>
        console.error('** error en el customer/affiliated: ', error),
    })
  }

  getAffiliatesAPIDebounced = (value) =>
    debounce(this.getAffiliatesAPI(value), 500)

  handleAffiliateOptions(event) {
    if (event?.target?.value === undefined) {
      return
    }
    if (!event) {
      this.clearCustomerFieldSearch()
      return
    }

    const value = event?.target?.value
    const isValue = event?.target && value && value.length >= 3

    if (isValue) {
      //Lógica para llamar servicio y mapear
      this.setState((prevState) =>({...prevState, affiliates: [] }))
      this.getAffiliatesAPIDebounced(value)
    }
  }

  onFocusAffiliateFilter(event) {
    event.persist()
    if (!this.state?.affiliateId) {
      this.clearAffiliateFieldSearch()
    }
  }

  getMaxDate() {
    return moment().add(1, 'years').format('YYYY-MM-DD');
  }

  onDateRange() {
    const { t } = this.props;
    const { dateStart, dateEnd } = this.state;
    const date1 = new Date(dateStart);
    const date2 = new Date(dateEnd);
    const isValidDates = date2 >= date1;
    if (!isValidDates) {
      this.props.isDateRangeValid(isValidDates);
      this.setState((prevState) => ({
        ...prevState,
        dateRangeInvalidText: t('fees.validDates'),
        isDateRangeValid: isValidDates,
      }))
      return;
    }
    const differenceMs = Math.abs(date2 - date1);
    const differenceYears = differenceMs / (1000 * 60 * 60 * 24 * 365);
    const isValidRange = differenceYears >= 0 && differenceYears <= 1;
    if (!isValidRange) {
      this.setState((prevState) => ({
        ...prevState,
        dateRangeInvalidText: t('appointment.new.invalidRangeDates'),
        isDateRangeValid: isValidRange,
      }))
      this.props.isDateRangeValid(isValidRange);
      return;
    }
    this.props.isDateRangeValid(true);
    this.setState((prevState) => ({
      ...prevState,
      dateRangeInvalidText: '',
      isDateRangeValid: true,
    }))
  }

  render() {
    const { financiers, financier, affiliateId, dateStart, dateEnd, dateRangeInvalidText, isDateRangeValid, affiliates, isLoading} = this.state;
    const { t, checkInvalid, disabled } = this.props;
    return (
      <GridItem
        id="provider-form"
        className="provider-form-component schedule-form"
        xs={12}
        sm={6}
        md={6}
      >
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <HomeRepairService />
            </CardIcon>
            <h3 className="card-icon-title">
              {t('appointment.financialData')}
            </h3>
          </CardHeader>
          {isLoading ? (
            <CustomLoader />
          ) : (
            <CardBody>
              <GridContainer>
                <GridItem className="fullWidth-input" xs={12} sm={6}>
                  <SelectInput
                    id="select-financier"
                    fullWidth
                    disabled={disabled}
                    label={t('appointment.new.provider.financier')}
                    elements={this.formatFinanciers(financiers)}
                    onSelectedValue={(value) =>
                      this.handleValue(value, 'financier')
                    }
                    onGetOptionLabel={(option) => option.value}
                    value={financier}
                    invalid={!financier && checkInvalid}
                    errorText={
                      !financier && checkInvalid ? t('error.required') : ''
                    }
                  />
                </GridItem>
                <GridItem
                  className="fullWidth-input validation-input"
                  xs={12}
                  sm={6}
                >
                  <SelectInput
                    ref={this.myRefAffiliateId}
                    className="no-padding"
                    label={t('appointment.new.provider.affiliate_identifier')}
                    elements={affiliates}
                    value={affiliateId}
                    onSelectedValue={(value) =>
                      this.handleValue(value, 'affiliateId')
                    }
                    onInputChange={(ev) => this.handleAffiliateOptions(ev)}
                    freeSolo={false}
                    isWaitLetters={true}
                    getOptionSelected={(option) => option?.id || null}
                    onFocus={(event) => this.onFocusAffiliateFilter(event)}
                    id="input-affiliateId"
                    disabled={disabled}
                  />
                </GridItem>
                <GridItem className="fullWidth-input" xs={12} sm={6}>
                  <DateInput
                    id="datetime-start"
                    disabled={disabled}
                    text={`${t('appointment.find.startDate')} *`}
                    minDate
                    onChangeValue={value => this.handleValue(value, 'dateStart')}
                    value={dateStart}
                    error={!dateStart && checkInvalid}
                    errorText={!dateStart && checkInvalid ? t('error.required') : ''}
                    inputProps={{
                      disabled: disabled,
                    }}
                  />
                </GridItem>
                <GridItem className="fullWidth-input" xs={12} sm={6}>
                  <DateInput
                    id="datetime-end"
                    disabled={disabled}
                    text={`${t('appointment.find.endDate')} *`}
                    minDate
                    min={dateStart}
                    maxDate
                    max={this.getMaxDate()}
                    onChangeValue={value => this.handleValue(value, 'dateEnd')}
                    value={dateEnd}
                    error={(!dateEnd && checkInvalid) || isDateRangeValid}
                    errorText={!dateEnd && checkInvalid ? t('error.required'): ''}
                    inputProps={{
                      disabled: disabled,
                    }}
                  />
                </GridItem>
                <GridItem
                  style={{ minHeight: 80 }}
                  className="fullWidth-input"
                  xs={12}
                >
                  <Typography
                    className="MuiFormHelperText-root helper-error"
                    variant="body2"
                    color="error"
                  >
                    {dateRangeInvalidText}
                  </Typography>
                </GridItem>
              </GridContainer>
            </CardBody>
          )}
        </Card>
      </GridItem>
    )
  }
}

ProviderForm.propTypes = {
  t: PropTypes.func,
  onFilledProvider: PropTypes.func.isRequired,
  onFilledFinancier: PropTypes.func.isRequired,
  onFilledAffiliate: PropTypes.func.isRequired,
  value: PropTypes.shape({
    dateEnd: PropTypes.string,
    dateStart: PropTypes.string,
    financier: PropTypes.any,
    provider: PropTypes.any,
    customerId: PropTypes.any,
  }),
  checkInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  customer: PropTypes.object,
  customerId: PropTypes.object,
  isDateRangeValid: PropTypes.func,
}

export default withTranslation()(ProviderForm);
