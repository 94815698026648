import './SelectInput.css';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';

import { Check } from '@material-ui/icons';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import customCheckboxRadioSwitch from '../../assets/components/customCheckboxRadioSwitch';
import { withTranslation } from 'react-i18next';

import { browserHistory } from 'react-router';

const useStyles = makeStyles(customCheckboxRadioSwitch);
const filter = createFilterOptions();

const SelectInput = (props) => {
  const {
    disabled,
    elements,
    onGetOptionLabel,
    onGetOptionSelected,
    label,
    multi,
    onSelectedValue,
    invalid,
    errorText,
    valueAccessor,
    disableCloseOnSelect,
    onInputChange,
    placeholder,
    isWaitLetters,
    freeSolo,
    onFocus,
    onBlur,
    id,
    margin,
    t,
    hasBoxStyle,
    link
  } = props;
  const classesCheck = useStyles();

  const [internalState, setInternalState] = useState([]);
  const typingTimeout = useRef(null); 
  const [waitingMessage, setWaitingMessage] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [inputText, setInputText] = useState('');  

  useEffect(()=> {
  if(!props.value){
    setInputText("");  
    }
  }, [props.value])

  const normalizeInput = (input) => {
    if(input){
      let trimmedInput = input.trim();
      trimmedInput = trimmedInput.replace(/\s+/g, ' ');
      return trimmedInput;
    } else return input
    };

  const emitValue = (event, value) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      setInternalState(null);  
      onSelectedValue("");  
      setInputText(''); 

      return;
    }
    if (Array.isArray(value)) {
      setInternalState(value);  
      onSelectedValue(value.map(v => v[valueAccessor]));  
      setInputText('');  

    } else {
      setInternalState(value); 
      onSelectedValue(value[valueAccessor]); 
    }
  };

  const handleInputChange = (event, value) => {
    setIsTyping(true);
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      setIsTyping(false);
    }, 1000);
    if (!value) {
      setInternalState(null);  
      onSelectedValue('');  
      setInputText('');  
    }
    setInputText(value); 
  };

  useEffect(() => {
    if (isTyping || props.elements?.length === 0) {
      return;
    }
    if (props.multi) {
      if (props.value) {
        setInternalState(props.elements.filter(e => props.value.indexOf(e[valueAccessor]) > -1));
      }
    } else {
      const valueId = props.value && typeof props.value === 'object' ? props.value[valueAccessor] : props.value;
      const matchedElement = props?.elements?.find(e => e && e[valueAccessor] === valueId);
      setInternalState(matchedElement || null);
    }
  }, [props.value, props.elements]);

  const isAdorned = (propsInput) => props.isAdornedStart ?
    {
      ...propsInput,
      startAdornment: (
        <InputAdornment position="start">
          <props.iconAdornedStart />
        </InputAdornment>
      )
    } : { ...propsInput };

  return (
    <GridContainer className={`select-input ${props.className}`}>
      <GridItem xs={12} sm={7} className="select-input-container">
        {multi ?
          <Autocomplete
            id={id}
            data-testid={id}
            disabled={disabled}
            options={elements}
            getOptionLabel={onGetOptionLabel}
            getOptionSelected={onGetOptionSelected}
            disableCloseOnSelect={disableCloseOnSelect}
            renderInput={params => (
              <TextField
                {...params}
                autoComplete='off'
                label={label}
                placeholder={placeholder}
                fullWidth
              />
            )}
            renderOption={(option, { selected }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checkedIcon={<Check className={classesCheck.checkedIcon} />}
                    icon={<Check className={classesCheck.uncheckedIcon} />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    classes={{
                      checked: classesCheck.checked,
                      root: classesCheck.checkRoot,
                  }}
                  />
                }
                label={option.name || option.value}
                classes={{ label: classesCheck.label, root: 'check-select' }}
              />
            )}
            onChange={emitValue}
            onInputChange={onInputChange}
            multiple={multi}
            value={internalState}
          /> :
          <Autocomplete  
            id={id}
            data-testid={id}
            disabled={disabled}
            freeSolo={freeSolo}
            options={elements}
            getOptionLabel={onGetOptionLabel}
            getOptionSelected={onGetOptionSelected}
            noOptionsText={t('common.noOptions')}
            clearOnBlur={true}
            onFocus={onFocus}
            onBlur={onBlur}
            renderInput={params => (
              <TextField
                style={margin ? {marginTop:'5px'}:{}}
                {...params}
                label={<Typography variant="body2"noWrap>{label}</Typography>}
                placeholder={placeholder}
                variant={hasBoxStyle ? "outlined" : "standard"}
                fullWidth
                InputProps={{
                  ...isAdorned(params.InputProps),
                  startAdornment: (() => {
                    
                    return internalState?.value && link ? (
                      <Button
                        id={`button-view-${internalState?.id}`}
                        data-testid={`button-view-${internalState?.id}`}
                        style={{
                          fontWeight: 'normal', 
                          textDecoration: 'underline', 
                          padding: 0, 
                          minWidth: 0,
                          color: '#007bff' 
                        }}
                        onClick={() => {
                          browserHistory.push({
                            state: { mode: 'view' },
                            pathname: `/ver-paciente/${internalState?.id}`,
                          });
                        }}
                      >
                        {internalState?.name ?? internalState?.value}
                      </Button>
                    ) : null;
                  })(),
                }}
                 error={invalid}
              />
            )}
            onChange={(event, value) => emitValue(event, value)} 
                        onInputChange={(event, value) => {
              handleInputChange(event, value); 
              onInputChange(event, value); 
            }} 
            inputValue={inputText}
            multiple={multi}
            value={!link && internalState} 
            filterOptions={(options, params) => {
              const { inputValue } = params;
              const normalizedInput = normalizeInput(inputValue).toLowerCase();
              const isExisting = options.length > 0;
              const filtered = filter(options, params);
              if (typingTimeout.current) {
                clearTimeout(typingTimeout.current);
              }
              typingTimeout.current = setTimeout(() => {
                if (isWaitLetters && inputValue.length <= 2) {
                  setWaitingMessage(t('common.waitingCharacters', { number: 3 }));
                } else if (inputValue.length >= 3) {
                  setWaitingMessage(null);
                }
              }, 500);
              if (waitingMessage && !filtered.some(option => option.value === waitingMessage)) {
                filtered.push({
                  inputValue,
                  value: waitingMessage,
                });
              }
              if (inputValue && inputValue.length >= 3 && !isExisting && !filtered.some(option => option.value === t('common.noResultsMatching', { word: inputValue }))) {
                filtered.push({
                  inputValue,
                  value: t('common.noResultsMatching', { word: inputValue }),
                });
              }
              const result = options.filter(option => {
                const optionLabel = normalizeInput(option.value ?? option.name).toLowerCase();
                const reversedOptionLabel = optionLabel.split(' ').reverse().join(' ');
                return (
                  optionLabel.includes(normalizedInput) || reversedOptionLabel.includes(normalizedInput)
                );
              });  
              const uniqueOptions = [...filtered, ...result].filter(
                (option, index, self) =>
                  self.findIndex(o => o.id === option.id) === index
              );
                return uniqueOptions;
            }}
          />
        }
        {(invalid && errorText) &&
          <FormHelperText className="helper-error">
            {errorText}
          </FormHelperText>
        }
      </GridItem>
    </GridContainer>
   )
}

SelectInput.defaultProps = {
  onGetOptionLabel: (option) => option ? (option.name || option.value) : '',
  onGetOptionSelected: () => {},
  invalid: false,
  multi: false,
  elements: [],
  className: '',
  disableCloseOnSelect: false,
  value: null,
  valueAccessor: 'id',
  onInputChange: () => {},
  placeholder: '',
  freeSolo: false,
  isWaitLetters: false,
  isAdornedStart: false,
  iconAdornedStart: null,
  id: '',
}

SelectInput.propTypes = {
  t: PropTypes.func,
  label: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  value: PropTypes.any,
  onSelectedValue: PropTypes.func,
  onInputChange: PropTypes.func,
  onGetOptionSelected: PropTypes.func,
  onGetOptionLabel: PropTypes.func,
  onClose: PropTypes.func,
  invalid: PropTypes.bool,
  errorText: PropTypes.string,
  multi: PropTypes.bool,
  chips: PropTypes.bool,
  className: PropTypes.string,
  valueAccessor: PropTypes.string,
  placeholder: PropTypes.string,
  freeSolo: PropTypes.bool,
  isWaitLetters: PropTypes.bool,
  isAdornedStart: PropTypes.bool,
  iconAdornedStart: PropTypes.object,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  hasBoxStyle: PropTypes.bool,
}

export default withTranslation()(SelectInput);
