import {
  CreateCustomerFinancierConnectionError,
  CreateCustomerFinancierError,
  CreateCustomerFinancierNotFoundError,
  CreateCustomerFinancierUsecase,
  CreateCustomerFinancierUsecaseDto,
  CustomerFinancier,
  Financier,
  GetFinanciersUsecase,
} from '../../../core'
import { FormAction, SnackbarColor } from '../../../util/interfaces'
import React, { useEffect, useState } from 'react'

import ButtonSpinner from '../../ButtonSpinner'
import { DIContainer } from '../../../Root'
import DateInput from '../../DateInput'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'
import PropTypes from 'prop-types'
import SelectInput from '../../SelectInput'
import Snackbar from '../../Snackbar/Snackbar'
import ValidationInput from '../../ValidationInput'
import config from '../../../config/config'
import { initialValueSnackbar } from '../../../util/constants'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type ComponentProps = {
  financierData?: CustomerFinancier
  personId: number
  onFinancierSubmitted: (f: CustomerFinancier, message: string) => void
  formAction: FormAction
}

interface FinancierForm {
  affiliateId: string
  customerId: number
  financierId: number
  dueDate: string
  homeCareEntryDate: string
}

const initData: FinancierForm = {
  affiliateId: '',
  customerId: 0,
  financierId: 0,
  dueDate: '',
  homeCareEntryDate: '',
}

const useStyles = makeStyles(() => ({
  createFinancier: {
    justifyContent: 'start',
  },
  formFields: {
    padding: '0 0 180px !important',
    '& .required-text': {
      paddingTop: 20,
    }
  },
}))

const dateToServer = config.getDateToServer()

const CreateFinancier = ({
  onFinancierSubmitted,
  personId,
  financierData,
  formAction,
}: ComponentProps) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [financiers, setFinanciers] = useState<Financier[] | []>([])
  const [financier, setFinancier] = useState<FinancierForm>(initData)
  const [save, setSave] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState(initialValueSnackbar)
  const [showAffiliateError, setShowAffiliateError] = useState(false);

  const companyLoggedString = localStorage.getItem("company");
  const companyLogged = companyLoggedString ? JSON.parse(companyLoggedString) : null;
  const isOmint = companyLogged.companyId === 11

  const getUsecase = DIContainer.get<GetFinanciersUsecase>(GetFinanciersUsecase)
  const createUsecase = DIContainer.get<CreateCustomerFinancierUsecase>(
    CreateCustomerFinancierUsecase
  )

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true })
    setTimeout(() => {
      setSnackbar(initialValueSnackbar)
    }, 5000)
  }

  const getMaxDate = () => {
    const maxDate = moment().add(20, 'years').format(dateToServer)
    return maxDate
  }

  const handleFinancier = (value: any, state: string) => {
    setFinancier((prev) => ({ ...prev, [state]: value }))
  }

  const getFinanciersOptions = async () => {
    setLoading(true)
    const result = await getUsecase.execute()
    if (result.isLeft()) {
      setLoading(false)
      const message = t('common.errorService')
      openSnackbar(SnackbarColor.danger, message)
    }
    const newFinanciers = result.getRight()
    setFinanciers(newFinanciers)
    setLoading(false)
  }

  const getCreateDataErrors = (
    dataError: CreateCustomerFinancierError
  ): void => {
    let message = t('common.errorService')
    if (dataError instanceof CreateCustomerFinancierNotFoundError) {
      message = t('financers.customer.notFoundMessage')
    }
    if (dataError instanceof CreateCustomerFinancierConnectionError) {
      message = t('confirm.error')
    }
    openSnackbar(SnackbarColor.danger, message)
  }

  const buildDataToSave = (): CreateCustomerFinancierUsecaseDto => {
    const { financierId, affiliateId, dueDate, homeCareEntryDate } = financier
    const validUntil = moment(dueDate).format(dateToServer)
    const homeCareDate = moment(homeCareEntryDate).format(dateToServer)
    const params = {
      affiliateId,
      customerId: personId,
      financierId,
      validUntil: validUntil === 'Fecha inválida' ? '' : validUntil,
      homeCareEntryDate: homeCareDate === 'Fecha inválida' ? '' : homeCareDate,
    }
    return params
  }

  const saveFinancier = async () => {
    setLoading(true)
    setSave(true)
    
    const { financierId, affiliateId, homeCareEntryDate } = financier
    
    if(isOmint && affiliateId.length !== 13){
      setShowAffiliateError(true)
    }else{
      setShowAffiliateError(false)
    }

    if (!financierId || !affiliateId || (!homeCareEntryDate && isOmint)) {
      setLoading(false)
      const message = t('common.messageWarning.fieldsComplete')
      return openSnackbar(SnackbarColor.warning, message)
    }

    const params = buildDataToSave()
    const result = await createUsecase.execute(params)
    if (result.isLeft()) {
      const dataError = result.getLeft()
      getCreateDataErrors(dataError)
      setLoading(false)
    }
    const resultData = result.getRight()

    const message =
      formAction === FormAction.EDIT
        ? t('financer.messageEditSuccess')
        : t('financer.messageAddSuccess')
    onFinancierSubmitted(resultData, message)
    setLoading(false)
  }

  useEffect(() => {
    getFinanciersOptions()
    
    if (formAction === FormAction.EDIT && financierData) {
      setFinancier({
        affiliateId: financierData.affiliateId,
        customerId: financierData.customerId,
        financierId: financierData.id,
        dueDate: financierData?.dueDate ?? initData.dueDate,
        homeCareEntryDate: financierData?.homeCareEntryDate ?? initData.homeCareEntryDate,
      })
    }
    return () => {
      setFinanciers([])
      setFinancier(initData)
      setSave(false)
      setLoading(false)
      setSnackbar(initialValueSnackbar)
    }
  }, [])

  const requiredText = t('common.fields.required')
  const msgshowAffiliateError = showAffiliateError ? t('message.AffiliateError'): ""


  return (
    <>
      <Snackbar
        place="tr"
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
      />
      <GridContainer className={classes.createFinancier}>
        <GridItem container xs={12} className={classes.formFields}>
          <GridItem xs={12} sm={6}>
            <SelectInput
              disabled={formAction === FormAction.EDIT}
              label={`${t('financer.title')} *`}
              elements={financiers}
              value={financier.financierId}
              onSelectedValue={(value: number) =>
                handleFinancier(value, 'financierId')
              }
              invalid={!financier.financierId && save}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <ValidationInput
              type="text"
              text={`${t('affiliate.number')} *`}
              value={financier.affiliateId}
              onChangeValue={(value: string) =>
                handleFinancier(value, 'affiliateId')
              }
              invalid={financier.affiliateId === '' && save}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <DateInput
              id="due-date"
              text={`${t('common.dueDate')}`}
              maxDate
              max={getMaxDate()}
              value={financier.dueDate}
              onChangeValue={(value: any) => handleFinancier(value, 'dueDate')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <DateInput
              id="homecare-entry-date"
              text={`${t('common.homecare.entry.date')}${isOmint ? ' *' : ''}`}
              maxDate
              max={getMaxDate()}
              value={financier.homeCareEntryDate}
              onChangeValue={(value: any) => handleFinancier(value, 'homeCareEntryDate')}
            />
          </GridItem>
        </GridItem>
        <GridItem container xs={12}>
          <GridItem xs={12} sm={6}>
            <p className="required-text">
              <small>*</small> {requiredText}
            </p>
            {msgshowAffiliateError && (
              <p className="required-text" style={{color:"red", fontWeight:400, padding: '0px 15px'}}>{msgshowAffiliateError}</p>
            )}
          </GridItem>
          <GridItem xs={12} sm={6} className="text-right">
            <ButtonSpinner
              className="create-financier-submit"
              onClick={() => saveFinancier()}
              disabled={loading}
              label={t('financer.save')}
              labelLoading={t('financer.saving')}
              loading={loading}
              typeButton="submit"
              id="button-save"
            />
          </GridItem>
        </GridItem>
      </GridContainer>
    </>
  )
}

CreateFinancier.propTypes = {
  personId: PropTypes.number,
  financierData: PropTypes.object,
  onFinancierSubmitted: PropTypes.func,
  formAction: PropTypes.any,
}

export default CreateFinancier
